import { navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "./logo"

const Header = ({ siteTitle, onToggleMenu }) => (
  <header className="header">
    <div>
      {/* <div className="logo">
        <Logo/>
      </div> */}
      <h1>
        <div onClick={ () => navigate('/')} role="link" className="logo">
          <Logo/>
        </div>
      </h1>
      <nav>
          <a className="menu-link" onClick={onToggleMenu} href="javascript:;">&nbsp;</a>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
