import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Menu = (props) => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        <li>
          <Link onClick={props.onToggleMenu} to="/">
            Home
          </Link>
        </li>
        <li>
          <p>
            <Link onClick={props.onToggleMenu} to="/collections/nothingness/">
              Nothingness
            </Link>
          </p>
          <p>
            <Link
              onClick={props.onToggleMenu}
              to="/collections/passage-through-time/"
            >
              Passage Through Time
            </Link>
          </p>
          <p>
            <Link onClick={props.onToggleMenu} to="/collections/the-abyss/">
              The Abyss
            </Link>
          </p>
          <p>
            <Link
              onClick={props.onToggleMenu}
              to="/collections/lost-and-found/"
            >
              Lost and Found
            </Link>
          </p>
          <p>
            <Link
              onClick={props.onToggleMenu}
              to="/collections/stolen-memories/"
            >
              Stolen Memories
            </Link>
          </p>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/contacts/">
            Contacts
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/about/">
            About
          </Link>
        </li>
      </ul>
    </div>
    <a className="close" onClick={props.onToggleMenu} href="javascript:;">
      Close
    </a>
  </nav>
);

Menu.propTypes = {
  onToggleMenu: PropTypes.func,
};

export default Menu;
