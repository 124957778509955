import React from "react"

const Logo = ({}) => (
  <svg id="logo" className="site-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 621.743 35.016">
    <title>Constanzia Yurashko</title>
    <g>
      <path d="M6.645,17.617A17.571,17.571,0,0,0,7.707,23.9a13.69,13.69,0,0,0,2.913,4.712,12.62,12.62,0,0,0,4.369,2.946,14.319,14.319,0,0,0,5.465,1.028,8.739,8.739,0,0,0,3.393-.686,13.17,13.17,0,0,0,3.05-1.782,15.024,15.024,0,0,0,2.5-2.467,16.488,16.488,0,0,0,1.816-2.775l2.364,1.508a22.026,22.026,0,0,1-2.433,3.238,15.822,15.822,0,0,1-3.255,2.741,17.085,17.085,0,0,1-4.181,1.9,17.622,17.622,0,0,1-5.208.72A19.873,19.873,0,0,1,10.945,33.6a17.67,17.67,0,0,1-5.825-3.77A16.775,16.775,0,0,1,1.385,24.3,17.269,17.269,0,0,1,5.24,5.3,18.355,18.355,0,0,1,11.151,1.46,19.8,19.8,0,0,1,18.775.038a16.027,16.027,0,0,1,4.917.72,17.465,17.465,0,0,1,4.095,1.9A17.122,17.122,0,0,1,31.094,5.4a20.28,20.28,0,0,1,2.484,3.238l-2.433,1.61A16.754,16.754,0,0,0,26.45,4.3a9.875,9.875,0,0,0-5.962-1.868,14.064,14.064,0,0,0-5.414,1.045,12.618,12.618,0,0,0-4.4,3.015,14.306,14.306,0,0,0-2.947,4.78A17.565,17.565,0,0,0,6.645,17.617Z" fill="#231f20"/>
      <path d="M56.232,34.99A18.873,18.873,0,0,1,48.985,33.6a18.263,18.263,0,0,1-5.859-3.8,17.866,17.866,0,0,1-3.907-5.619A16.746,16.746,0,0,1,37.8,17.308a16.23,16.23,0,0,1,1.422-6.75,17.04,17.04,0,0,1,3.907-5.482,18.562,18.562,0,0,1,5.859-3.684,20.079,20.079,0,0,1,14.495,0,18.584,18.584,0,0,1,5.859,3.684,17.061,17.061,0,0,1,3.906,5.482,16.23,16.23,0,0,1,1.422,6.75,16.746,16.746,0,0,1-1.422,6.871A17.888,17.888,0,0,1,69.339,29.8a18.284,18.284,0,0,1-5.859,3.8A18.879,18.879,0,0,1,56.232,34.99Zm0-1.679a10.4,10.4,0,0,0,5.071-1.2A10.935,10.935,0,0,0,65,28.787a15.4,15.4,0,0,0,2.279-5.071,25.259,25.259,0,0,0,.771-6.408A24.321,24.321,0,0,0,67.283,11,14.861,14.861,0,0,0,65,6.052a10.637,10.637,0,0,0-3.7-3.221,10.78,10.78,0,0,0-5.071-1.148,11.063,11.063,0,0,0-5.14,1.148,10.387,10.387,0,0,0-3.718,3.221A14.809,14.809,0,0,0,45.13,11a24.836,24.836,0,0,0-.754,6.305,25.794,25.794,0,0,0,.754,6.408,15.352,15.352,0,0,0,2.245,5.071,10.366,10.366,0,0,0,8.857,4.523Z" fill="#231f20"/>
      <path d="M80.326.792h3.152l21.9,24.432h.24V.792h2.467v34.2h-1.3L83.068,7.817h-.206V34.2H80.326Z" fill="#231f20"/>
      <path d="M127.7,32.866a8.993,8.993,0,0,0,2.45-.343,6.973,6.973,0,0,0,2.159-1.011,5.385,5.385,0,0,0,1.524-1.646,4.294,4.294,0,0,0,.583-2.244,4.794,4.794,0,0,0-.788-2.724,9.542,9.542,0,0,0-2.073-2.193,19.593,19.593,0,0,0-2.93-1.885q-1.645-.875-3.375-1.765t-3.375-1.851a16.283,16.283,0,0,1-2.93-2.159,10.253,10.253,0,0,1-2.073-2.673,7.074,7.074,0,0,1-.788-3.392,8.667,8.667,0,0,1,.72-3.546,7.955,7.955,0,0,1,2.107-2.827,10.124,10.124,0,0,1,3.41-1.885,14.511,14.511,0,0,1,4.66-.685,12.563,12.563,0,0,1,7.5,1.987,11.125,11.125,0,0,1,3.975,5.757l-2.878,1.3A10.952,10.952,0,0,0,132.426,4.1,8.34,8.34,0,0,0,126.7,2.2a7.819,7.819,0,0,0-2.759.445,6.24,6.24,0,0,0-1.97,1.148A4.628,4.628,0,0,0,120.4,7.234a3.961,3.961,0,0,0,.805,2.4,9.772,9.772,0,0,0,2.142,2.073,25.669,25.669,0,0,0,3.033,1.885q1.7.908,3.461,1.833t3.461,1.953a20.972,20.972,0,0,1,3.032,2.21,10.048,10.048,0,0,1,2.142,2.621,6.417,6.417,0,0,1,.805,3.187,9.339,9.339,0,0,1-.753,3.735,8.393,8.393,0,0,1-2.262,3.05,11.042,11.042,0,0,1-3.752,2.056,16.441,16.441,0,0,1-5.226.754,12.707,12.707,0,0,1-7.642-2.073,11.933,11.933,0,0,1-4.18-5.773l3.015-1.233a10.076,10.076,0,0,0,3.5,5.122A9.239,9.239,0,0,0,127.7,32.866Z" fill="#231f20"/>
      <path d="M173.612.792V2.951h-12.1V34.2H155.9V2.951H143.8V.792Z" fill="#231f20"/>
      <path d="M191.622.038,207.35,34.2h-5.723l-5.105-11.137H182.2L176.922,34.2h-2.81L190.218.038Zm-8.43,20.868h12.3L189.429,7.68Z" fill="#231f20"/>
      <path d="M212.476.792h3.152l21.9,24.432h.24V.792h2.467v34.2h-1.3L215.218,7.817h-.206V34.2h-2.536Z" fill="#231f20"/>
      <path d="M278.9.792l-24.158,31.25H278.9V34.2h-31.73v-.822L270.645,2.951H247.173V.792Z" fill="#231f20"/>
      <path d="M286.8.792h5.62V34.2H286.8Z" fill="#231f20"/>
      <path d="M316.7.038,332.427,34.2H326.7L321.6,23.065H307.275L302,34.2h-2.81L315.294.038Zm-8.43,20.868h12.3L314.506,7.68Z" fill="#231f20"/>
      <path d="M352.185.792,363.767,17.72,375.314.792h3.05L365.411,19.776V34.2h-5.62V20.289L346.428.792Z" fill="#231f20"/>
      <path d="M382.837.792h5.62v21.69a10.908,10.908,0,0,0,.736,4.009,9.671,9.671,0,0,0,5.38,5.517,11.7,11.7,0,0,0,8.618,0,9.556,9.556,0,0,0,5.311-5.517,11.145,11.145,0,0,0,.72-4.009V.792h2.467v21.69a13.773,13.773,0,0,1-.839,4.831,10.643,10.643,0,0,1-2.6,3.975A12.412,12.412,0,0,1,403.7,34a19.762,19.762,0,0,1-6.647.994A17.375,17.375,0,0,1,390.94,34a12.8,12.8,0,0,1-4.455-2.724,11.483,11.483,0,0,1-2.724-4.026,12.986,12.986,0,0,1-.925-4.9Z" fill="#231f20"/>
      <path d="M418.485.792h11.582a19.179,19.179,0,0,1,5.277.651,11.352,11.352,0,0,1,3.769,1.8A7.534,7.534,0,0,1,441.391,6a7.994,7.994,0,0,1,.771,3.5,7.334,7.334,0,0,1-.583,2.93,7.954,7.954,0,0,1-1.662,2.45,10.266,10.266,0,0,1-2.587,1.885,14.668,14.668,0,0,1-3.324,1.233L446.171,34.2h-6.51L428.045,18.507H424.1V34.2h-5.62Zm5.62,16.036h3.255a11.794,11.794,0,0,0,3.5-.5,8.379,8.379,0,0,0,2.776-1.422,6.521,6.521,0,0,0,1.816-2.244A6.613,6.613,0,0,0,436.1,9.7a7.191,7.191,0,0,0-.514-2.759,6.279,6.279,0,0,0-1.457-2.159,6.579,6.579,0,0,0-2.244-1.4,7.922,7.922,0,0,0-2.844-.5H424.1Z" fill="#231f20"/>
      <path d="M466.381.038,482.109,34.2h-5.723l-5.105-11.137H456.957L451.68,34.2h-2.81L464.976.038Zm-8.43,20.868h12.3L464.188,7.68Z" fill="#231f20"/>
      <path d="M497.576,32.866a8.993,8.993,0,0,0,2.45-.343,6.973,6.973,0,0,0,2.159-1.011,5.385,5.385,0,0,0,1.524-1.646,4.294,4.294,0,0,0,.583-2.244A4.794,4.794,0,0,0,503.5,24.9a9.542,9.542,0,0,0-2.073-2.193,19.593,19.593,0,0,0-2.93-1.885q-1.645-.875-3.375-1.765t-3.375-1.851a16.283,16.283,0,0,1-2.93-2.159,10.253,10.253,0,0,1-2.073-2.673,7.074,7.074,0,0,1-.788-3.392,8.667,8.667,0,0,1,.72-3.546,7.955,7.955,0,0,1,2.107-2.827A10.124,10.124,0,0,1,492.2.723a14.511,14.511,0,0,1,4.66-.685,12.563,12.563,0,0,1,7.5,1.987,11.125,11.125,0,0,1,3.975,5.757l-2.878,1.3A10.952,10.952,0,0,0,502.3,4.1a8.34,8.34,0,0,0-5.722-1.9,7.819,7.819,0,0,0-2.759.445,6.24,6.24,0,0,0-1.97,1.148,4.628,4.628,0,0,0-1.576,3.444,3.961,3.961,0,0,0,.805,2.4,9.772,9.772,0,0,0,2.142,2.073,25.669,25.669,0,0,0,3.033,1.885q1.7.908,3.461,1.833t3.461,1.953a20.972,20.972,0,0,1,3.032,2.21,10.048,10.048,0,0,1,2.142,2.621,6.417,6.417,0,0,1,.805,3.187,9.339,9.339,0,0,1-.753,3.735,8.393,8.393,0,0,1-2.262,3.05,11.042,11.042,0,0,1-3.752,2.056,16.441,16.441,0,0,1-5.226.754,12.707,12.707,0,0,1-7.642-2.073,11.933,11.933,0,0,1-4.18-5.773l3.015-1.233a10.076,10.076,0,0,0,3.5,5.122A9.239,9.239,0,0,0,497.576,32.866Z" fill="#231f20"/>
      <path d="M521.819,18.165V34.2H516.2V.792h5.62V16.006h16.79V.792h5.62V34.2h-5.62V18.165Z" fill="#231f20"/>
      <path d="M552.975.792h5.62V15.6L576.173.792h3.667L563.152,14.841,584.328,34.2h-7.162L558.595,17.24V34.2h-5.62Z" fill="#231f20"/>
      <path d="M603.151,34.99A18.873,18.873,0,0,1,595.9,33.6a18.263,18.263,0,0,1-5.859-3.8,17.866,17.866,0,0,1-3.907-5.619,16.746,16.746,0,0,1-1.422-6.871,16.23,16.23,0,0,1,1.422-6.75,17.04,17.04,0,0,1,3.907-5.482A18.562,18.562,0,0,1,595.9,1.392a20.079,20.079,0,0,1,14.495,0,18.584,18.584,0,0,1,5.859,3.684,17.061,17.061,0,0,1,3.906,5.482,16.23,16.23,0,0,1,1.422,6.75,16.746,16.746,0,0,1-1.422,6.871,17.888,17.888,0,0,1-3.906,5.619,18.284,18.284,0,0,1-5.859,3.8A18.879,18.879,0,0,1,603.151,34.99Zm0-1.679a10.4,10.4,0,0,0,5.071-1.2,10.935,10.935,0,0,0,3.7-3.324,15.4,15.4,0,0,0,2.279-5.071,25.259,25.259,0,0,0,.771-6.408A24.321,24.321,0,0,0,614.2,11a14.861,14.861,0,0,0-2.279-4.951,10.637,10.637,0,0,0-3.7-3.221,10.78,10.78,0,0,0-5.071-1.148,11.063,11.063,0,0,0-5.14,1.148,10.387,10.387,0,0,0-3.718,3.221A14.809,14.809,0,0,0,592.049,11a24.836,24.836,0,0,0-.754,6.305,25.794,25.794,0,0,0,.754,6.408,15.352,15.352,0,0,0,2.245,5.071,10.366,10.366,0,0,0,8.857,4.523Z" fill="#231f20"/>
    </g>
  </svg>
)

Logo.propTypes = {}

Logo.defaultProps = {}

export default Logo



