import React from "react"
import PropTypes from "prop-types"
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from "gatsby"

import '../assets/scss/main.scss'

import Header from "./header"
import Menu from "./menu"
import Footer from "./footer"

class LayoutBody extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isMenuVisible: false
    }

    this.handleToggleMenu = this.handleToggleMenu.bind(this)
  }

  handleToggleMenu() {
    this.setState(prevState => ({
      isMenuVisible: !prevState.isMenuVisible 
    }))
  }

  render() {
    return (
      <div className={`body ${this.state.isMenuVisible ? 'is-menu-visible' : ''}`}>
        <div className="site-wrapper">
          <Helmet>
            <link href="https://fonts.googleapis.com/css?family=Lato|Open+Sans" rel="stylesheet"/>
          </Helmet>

          <Header siteTitle={this.props.site.siteMetadata.title} onToggleMenu={this.handleToggleMenu} />

          <div>
            <main>{this.props.children}</main>
          </div>

          <Footer />
        </div>

        <Menu onToggleMenu={this.handleToggleMenu} />
      </div>
    )
  }
}

const Layout = ({children}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <LayoutBody site={data.site} children={children} />
    )}
  />
)

LayoutBody.propTypes = {
  site: PropTypes.object,
  children: PropTypes.node.isRequired,
  isHomepage: PropTypes.bool,
}

export default Layout
